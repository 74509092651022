import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { IntroSection } from '../components/IntroSection';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <IntroSection mdxType="IntroSection">
    <h2>Fudge</h2>
      <pre><code parentName="pre" {...{}}>{`Welcome to the wanderlost show!
`}</code></pre>
    </IntroSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      